<template>
  <!-- 忘记密码 -->
  <div class="changePassword">
    <van-nav-bar
      title="忘记密码"
      left-text
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div>
      <!-- <van-field
        v-model="username"
        name="username"
        label="用户名"
        placeholder="请输入"
        input-align="right"
        required
      />-->
      <div v-show="!codeTrue">
        <van-field
          v-model="phone"
          name="phone"
          label="手机号码"
          placeholder="请输入"
          input-align="right"
          required
        />
        <van-field
          v-model="code"
          center
          required
          clearable
          label="验证码"
          placeholder="请输入验证码"
          :border="false"
          use-button-slot
        >
          <van-button
            slot="button"
            size="small"
            type="info"
            :disabled="!istel"
            @click="b4gainCode"
          >{{time}}</van-button>
        </van-field>
        <div style="margin: 16px;">
          <van-button
            plain
            slot="button"
            size="small"
            type="info"
            round
            block
            :disabled="!bfcodeTrue"
            @click="codeTrue=true"
          >下一步</van-button>
        </div>
      </div>
      <!-- :rules="[{ required: true, message: '请填写原密码' }]" -->
      <van-form v-show="codeTrue" @submit="onSubmit">
        <van-field
          v-model="userinfo.empcode"
          clickable
          type="text"
          name="empcode"
          label="工号"
          placeholder="工号"
          :readonly="true"
        />
        <van-field
          v-model="userinfo.empname"
          clickable
          type="empname"
          name="empname"
          label="姓名"
          placeholder="姓名"
          :readonly="true"
        />
        <van-field
          v-model="newPassword1"
          required
          clickable
          type="password"
          name="newPassword1"
          label="新密码"
          placeholder="新密码"
        />
        <van-field
          v-model="newPassword2"
          required
          clickable
          type="password"
          name="newPassword2"
          label="确认密码"
          placeholder="确认密码"
        />
        <!-- <p class="pswDesc">
          密码必须是8-16位的英文字母、数字、字符组合（不能 是纯数字）
        </p>-->
        <div style="margin: 16px;">
          <van-button
            slot="button"
            plain
            size="small"
            type="info"
            round
            block
            @click="codeTrue=false"
          >上一步</van-button>
        </div>
        <div style="margin: 16px;">
          <van-button round block type="info" :disabled="!canSave" native-type="submit">提交</van-button>
        </div>
        <div class="time2" v-show="time2" @click="onClickLeft()">{{time2}}</div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast, Notify, NumberKeyboard } from 'vant'
import { getSmsCode, changePwdByPhone, getPhoneEmpInfo } from '@api/login.js'
export default {
  data() {
    return {
      username: '',
      phone: '',
      key: '',
      code: '',
      s: 60,
      s2: 10,
      isClick: true,
      newPassword1: '',
      newPassword2: '',
      showBoard1: false,
      showBoard2: false,
      showBoard3: false,
      time: '获取验证码',
      time2: '',
      codeTrue: false,
      userinfo: {}
    }
  },
  computed: {
    canSave() {
      if (
        this.phone &&
        this.newPassword1 &&
        this.newPassword2 &&
        this.newPassword1 == this.newPassword2
      ) {
        return true
      } else {
        return false
      }
    },
    bfcodeTrue() {
      if (this.code !== '' && this.code === this.key) {
        return true
      } else {
        return false
      }
    },
    istel() {
      if (/^1[3456789]\d{9}$/.test(this.phone)) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    onClickLeft() {
      this.$router.push('/login')
    },
    onClickRight() {},
    // 提交
    onSubmit() {
      const data = {
        // username: this.username,
        mobilephone: this.phone,
        newpwd1: this.newPassword1,
        newpwd2: this.newPassword2
      }
      changePwdByPhone(data).then(res => {
        if (res.iserror === '0') {
          Toast.success('修改成功!')
        } else {
          Toast.fail(res.errormsg)
        }
      })
    },
    b4gainCode() {
      // 根据手机号码获取员工相关信息
      getPhoneEmpInfo({ mobilephone: this.phone }).then(res => {
        if (res.iserror == 1) {
          Notify({
            type: 'danger',
            message: '获取验证码失败！原因：' + res.errormsg
          })
        } else if (res.data.length == 0) {
          Notify({
            type: 'danger',
            message: '不是有效的员工手机，请重新输入'
          })
        } else {
          this.userinfo = res.data[0]
          this.gainCode()
        }
      })
    },
    async gainCode() {
      if (this.isClick) {
        const res = await getSmsCode({ mobilephone: this.phone }) // 获取验证码接口
        // console.log(resA);
        // const res = resA[0] //接口传入的是数组-_||
        if (res.iserror == 0) {
          Notify({ type: 'success', message: '发送验证码成功' })
          this.key = res.topvalue
          if (res.topvalue == 1234) {
            this.code = 1234
          }
        } else if (res.iserror == 1) {
          Notify({
            type: 'danger',
            message: '获取验证码失败！原因：' + res.errormsg
          })
        }
        this.isClick = false
        this.s = 60
        this.time = this.s + 's'
        let interval = setInterval(() => {
          this.s--
          this.time = this.s + 's'
          if (this.s < 0) {
            this.time = '重新获取'
            this.isClick = true
            clearInterval(interval)
          }
        }, 1000)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.changePassword {
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .van-form {
    .pswDesc {
      padding: 20px 30px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 40px;
      color: #333333;
    }
    .forgetPwd {
      text-align: right;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 40px;
      color: #1989fa;
      span {
        padding: 20px;
      }
    }
    .time2 {
      color: red;
      text-align: center;
      font-size: 24px;
    }
  }
}
</style>